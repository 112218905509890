@media (max-width: 600px) {
  .mobile-container {
    width: 90%;
    margin: auto;
  }
}

@media only screen and (max-width: 600px) {
  .loadingMessage {
    font-size: 16px;
  }
}

.loadingMessage {
  overflow-wrap: break-word;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

.typewriterEffect1 {
  /* border-right: .15em solid; */ /* Removed blinking cursor */
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 0;
  max-width: 100%;
  animation: 
    typing 1.75s steps(30, end) forwards; /* Reduced from 3.5s to 1.75s */
  animation-iteration-count: 1; 
}

.typewriterEffect2 {
  /* border-right: .15em solid; */ /* Removed blinking cursor */
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 0;
  max-width: 100%;
  animation: 
    typing 1.75s steps(30, end) 1.75s forwards; /* Reduced from 3.5s to 1.75s and delay to 1.75s */
  animation-iteration-count: 1; 
}

.typewriterEffect3 {
  /* border-right: .15em solid; */ /* Removed blinking cursor */
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  width: 0;
  max-width: 100%;
  animation: 
    typing 1.75s steps(30, end) 3.5s forwards; /* Reduced from 3.5s to 1.75s and delay from 7s to 3.5s */
  animation-iteration-count: 1; 
}


.fadeIn {
  opacity: 0;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {opacity:0;}
  100% {opacity:1;}
}

.loaderContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  flex-direction: column;
}

.loadingMessage {
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  word-wrap: break-word;
  margin-bottom: 20px;
}

.loadingOverlay {
  position: relative;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImage {
  position: relative;
  width: 100px; 
  height: 100px; 
  object-fit: cover;
  z-index: 2;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  border: 3px solid transparent;
  border-top: 3px solid #ffffff; 
  border-radius: 50%;
  animation: spin 2s linear infinite;
  z-index: 1;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



@keyframes placeholderFadeIn {
  0% { opacity:0; }
  50% { opacity:1; }
  100% { opacity:0; }
}

.rotating-placeholder {
  animation: placeholderFadeIn 5s ease-in-out infinite;
}



