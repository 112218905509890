@keyframes slideFadeInOut {
  0% { opacity: 0; transform: translateX(-50px); }
  50% { opacity: 1; transform: translateX(0); }
  100% { opacity: 0; transform: translateX(50px); }
}

.error-message {
  margin-top: 30px;
  animation-name: slideFadeInOut;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  /* Subtle glow effect */
  text-shadow: 0 0 5px #ffffff, 0 0 10px #ffffff;
}
